import React, { useCallback } from 'react'
import { I18n } from '@front/volcanion'

import _ from 'lodash'
import { Form, hooks } from '@front/volcanion'
import { DialogActions, Button } from '@mui/material'

const withForm = (Component) => ({
  name,
  onClose,
  allowConfirm = true,
  allowCancel = true,
  submitLabel = I18n.t('action.confirm'),
  cancelLabel = I18n.t('action.return'),
  formProps: inputFormProps,
  children,
  onCancel: onInputCancel = _.noop,
  ...custom
}) => {
  const [{ isOpen, extra, state }] = hooks.useDialog(name)
  const title = _.get(extra, 'title')
  const description = _.get(extra, 'description')
  const extraFormProps = _.get(extra, 'formProps')
  const formName = `${name}_dialog_form` || 'dialog_form'
  const requestSubmit = hooks.useGlobalFormFunction(formName, 'requestSubmit')
  const formProps = {
    name: formName,
    initialValues: {},
    initialState: {},
    disableParentSubmitListener: true,
    disableParentDisabledListener: true,
    submitChangesOnly: false,
    submitOnEmpty: true,
    ...inputFormProps,
    ...extraFormProps,
    onSubmitSuccess: onClose,
    closeOnSuccess: false
  }

  const onCancel = useCallback(async () => {
    await onInputCancel(state)
    onClose()
  }, [onInputCancel, state])



  return (
    <Form {...formProps} status={{ isReady: isOpen }} >
      <Component name={name} title={title} description={description} extra={extra} state={state} onClose={onClose} {...custom}>
        {children}
        <DialogActions>
          {!!allowCancel && <Button onClick={onCancel}>{cancelLabel}</Button>}
          {!!allowConfirm && <Button onClick={() => requestSubmit(state)}>{submitLabel}</Button>}
        </DialogActions>
      </Component>
    </Form>
  );
}
export default withForm